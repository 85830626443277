/* Loading.css */

.loading-overlay {
    position: fixed;
  
    top: 0;
  
    left: 0;
  
    width: 100%;
  
    height: 100%;
  
    background: rgba(
      107,
      107,
      107,
      0.3
    ); /* Semi-transparent white background for a glassy effect */
  
    backdrop-filter: blur(
      5px
    ); /* Apply a blur effect to create the glassy overlay */
  
    display: flex;
  
    flex-direction: column;
  
    gap: 10px;
  
    justify-content: center;
  
    align-items: center;
  
    z-index: 9999;
  
    color: white;
  }
  
  .loading-spinner {
    width: 50px;
  
    height: 50px;
  
    border: 5px solid #f3f3f3;
  
    border-top: 5px solid #3498db;
  
    border-radius: 50%;
  
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  